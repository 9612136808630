import { ReactComponent as Erza } from './erza_nice.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import ColorHelper from 'color-to-name';
import { useState } from 'react';
import './App.css';
require("react-toggle/style.css")


function App() {
  const colors = [
    "#c12823",
    "#b9303a",
    "#9b2231",
    "#62272d",
    "#eb7020",
    "#f7e008",
    "#1fd2bf",
    "#0da4cf",
    "#0b7ae5",
    "#105bc4",
    "#1236b1",
    "#273679",
    "#242276",
    "#40a32c",
    "#278747",
    "#1a8162",
    "#286544",
    "#836cba",
    "#492ca2",
    "#848f91",
    "#758384",
    "#556268",
    "#694e39",
    "#4f4641",
    "#137fa6",
    "#af3067",
    "#d091b0",
    "#ebe5a9",
    "#683136",
    "#000"
  ];

  const colorFriendlyName = []
  
  colors.forEach(function (value, index) {
    colorFriendlyName[index] = ColorHelper.findClosestColor(value).name
  })

  console.log(colorFriendlyName)

  const [background, setBackground] = useState("#fffcf9")
  const [current, setCurrent] = useState(null)

  return (
    <div className="App">
      <h1>DDD Color Swatches</h1>
      {current !== null && <h2>Copied</h2>}
      <div className="container">
        {colorFriendlyName.map((name, index) => (
          <div key={index} className="card">
            <div style={{
              background: colors[index],
              filter: "brightness(85%)",
              boxShadow: colors[index] === background ? "0 0 5px #000" : ""
            }}
            className="box" onClick={() => setBackground(colors[index])} />
            <CopyToClipboard text={name}>
              <p style={{color: colors[index] }} onClick={()=> setCurrent(colors[index])}>{name}</p>
            </CopyToClipboard>
            </div>
        ))}
      </div>
      <div className='img-container'>
        <Erza fill={background === "#fffcf9" ? "#000" : background} stroke={background === "#fffcf9" ? "#000" : background}/>
      </div>
    </div>
  );
}

export default App;
